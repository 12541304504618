<template>
  <EditorWrapper
    :actions="designActions"
    :class="`${dynamicClasses}`"
    :classes="`mt-n5 pa-2`"
    :is_design_mode="isDesignMode"
    :context="context"
    :definition="definition"
    :aut-field="`${path}${typeof index != 'undefined' ? '-' + index : ''}`"
    :aut-field-name="`${definition.name}`"
    :aut-field-type="field && field.type"
    @edit_field="displayEditDialog = true"
    @update:definition="updateFieldDefinition"
    @remove_field="$emit('remove_field')"
    @copy_field="$emit('copy_field')"
    @edit_permissions="permissionDialogToggle = true"
    class="behaviour_field"
  >
    <EditorActions
      :context="context"
      v-if="!isDesignMode"
      :actions="nonDesignActions"
      @edit_permissions="permissionDialogToggle = true"
    />

    <EditFieldDialog
      v-if="displayEditDialog"
      :context="context"
      :definition="definition"
      @update:field="updateFieldDefinition"
      @close="displayEditDialog = false"
    />

    <PermissionEditor
      v-if="permissionDialogToggle"
      :context="context"
      mode="field"
      :definition="definition"
      @close="permissionDialogToggle = false"
      @update="updateFieldPermissions"
    />

    <!-- clickHandler does not work if put on component -->
    <div
      v-if="field"
      :aut-field-wrapper="field.name"
      @click="clickHandler"
      :class="field.click_action ? 'behavior_clickable' : ''"
    >
      <component
        v-if="currentComponent && definition.mode != 'ignored'"
        :key="updateDynamicComponent"
        v-bind:is="currentComponent"
        v-model="field"
        :context="context"
        :path="path"
        :index="index"
        :design="isDesignMode"
        @trigger-action="triggerAction"
        @update_field="updateFieldDefinition"
        @hide_field="$emit('hide_field')"
        v-on="$listeners"
      ></component>
    </div>
  </EditorWrapper>
</template>

<script>
import { defaultsDeep, isEqual, isPlainObject } from "lodash";
import {
  safeTypeToArray,
  clone,
  safeClone,
  getDataVariablesInFieldDefinition,
  getKeyFromDataVariable,
  evaluateConditions,
} from "@/util.js";
import { STORE_CONSTS } from "@/components/fields/store.js";
import { componentMixin } from "@/components/mixin.js";
import permissionsMixin from "@/components/permissionsMixin.js";
import EditorWrapper from "@/components/editor/EditorWrapper";
const debug = require("debug")("atman.components.field");

const ACTIONS = {
  EDIT: "edit-field",
  REMOVE: "remove-field",
  CLONE: "clone-field",
  MOVE: "move-field",
  EDIT_PERMISSIONS: "edit_permissions",
};
const designActions = [
  {
    id: ACTIONS.EDIT,
    label: "Edit Field",
    icon: "mdi-pencil",
    event: "edit_field",
    param: "",
  },
  {
    id: ACTIONS.EDIT_PERMISSIONS,
    label: "Change Permissions",
    icon: "mdi-key",
    event: "edit_permissions",
    param: "",
  },
  {
    id: ACTIONS.REMOVE,
    label: "Remove Field",
    icon: "mdi-delete",
    confirmation: "Are you sure you want to continue?",
    event: "remove_field",
    param: "",
  },
  {
    id: ACTIONS.CLONE,
    label: "Clone",
    icon: "mdi-content-copy",
    event: "copy_field",
  },
  {
    id: ACTIONS.MOVE,
    label: "Move",
    icon: "mdi-drag",
    classes: "dragger",
  },
];

const isAnAction = (field) => {
  return field.type == "action" || field.type == "actions";
};

const areSimilarVariables = (source, target) => {
  return source.replaceAll(".", "->") == target.replaceAll(".", "->");
};

export default {
  name: "Field",
  data() {
    return {
      designActions: [],
      state: {},
      currentComponent: null,
      designComponent: null,
      field: {},
      updateDynamicComponent: 1,
      displayEditDialog: false,
    };
  },
  mixins: [componentMixin, permissionsMixin],
  components: {
    EditorWrapper,
    EditFieldDialog: () => import("./EditFieldDialog"),
    EditorActions: () => import("@/components/editor/EditorActions"),
  },
  computed: {
    nonDesignActions() {
      const methodDebug = debug.extend("nonDesignActions"); //eslint-disable-line
      let result = [];
      const fieldPermissionsObj = this.definition?._permissions;
      if (fieldPermissionsObj) {
        result = [...this.permissionsActions];
      }
      return result;
    },
    name() {
      const methodDebug = debug.extend("name"); //eslint-disable-line
      return this.definition.name;
    },
    isDesignMode() {
      const methodDebug = debug.extend("isDesignMode"); //eslint-disable-line
      const isNotAColumn = typeof this.index == "undefined";
      const isNotDynamic = this?.definition?._ignore_in_page_editor_ !== true;
      return (
        isNotAColumn &&
        isNotDynamic &&
        this.$store?.state?.[this.context]?.design
      );
    },
    dynamicClasses() {
      const methodDebug = debug.extend("dynamicClasses"); //eslint-disable-line
      if (this.field.mode == "hidden" && !this.isDesignMode) {
        return "hiddenInPage";
      }
      const classes = safeTypeToArray(this.field?.display?.classes);
      return classes.join(" ");
    },
  },

  props: {
    dont_allow_edit: {
      type: Boolean,
    },
    definition: {
      type: Object,
    },
    context: {
      type: String,
    },
    path: {
      type: String,
    },
    index: {
      type: Number,
    },
  },
  watch: {
    "field.value": function (newValue, oldValue) {
      const methodDebug = debug.extend(`watch_field.value_${this?.field?.name}`); //eslint-disable-line
      methodDebug(
        `In Field [${this.field.name}], value updated [`,
        this.field.value,
        "]",
        newValue,
        oldValue
      );
      if (typeof this.field.value === "undefined") {
        return;
      }
      this.updateData();
    },
  },
  created() {
    const component = this;
    const methodDebug = debug.extend("created"); //eslint-disable-line
    methodDebug("in created of field", this.definition);
    component.loadField();
  },
  methods: {
    clickHandler() {
      const methodDebug = debug.extend("clickHandler"); //eslint-disable-line
      const component = this;
      const clickAction = component?.field?.click_action;
      if (!clickAction) {
        return;
      }
      methodDebug(`In trigger Click action`, clickAction);
      this.triggerAction({ definition: { value: clone(clickAction) } });
    },
    deriveActions() {
      const methodDebug = debug.extend("deriveActions"); //eslint-disable-line
      const component = this;
      methodDebug(`component.field`, component.field);
      const actions = designActions.filter((action) => {
        let result = true;
        if (component.dont_allow_edit) {
          result = action.id != ACTIONS.EDIT;
          if (!result) {
            return result;
          }
        }
        if (component.field?._system_constraints?.mandatory === true) {
          result = action.id != ACTIONS.REMOVE && action.id != ACTIONS.CLONE;
          if (!result) {
            return result;
          }
        }
        return true;
      });
      this.designActions = actions;
    },
    checkPermission() {
      const component = this;
      const debugKey = `checkPermission_${component.definition.name}`;
      const methodDebug = debug.extend(debugKey); //eslint-disable-line
      const permission = component.definition?._permissions?.permission;
      if (!permission) {
        methodDebug(`No permissions found. Returning empty`);
        return {};
      }

      const permissionValue =
        typeof permission == "string" ? permission : permission.value;

      let behavior =
        permission?.behavior ||
        component.getFeatureValue("permissions.field.default_behavior");

      let canDo = false;
      if (permissionValue) {
        debug(
          `For ${component.definition.name}: User has permission [${permissionValue}]? [${canDo}]`
        );
        canDo = this.$store.getters[`user/canPerformAction`](permissionValue);
      } else if (permission.conditions) {
        const outcome = evaluateConditions(permission.conditions, {
          _context: component.context,
        });
        canDo = !outcome.result;
        behavior = outcome.value;
        debug(
          `For ${component.definition.name}: conditions evaluated to [${canDo}]`
        );
      }

      let attributes = {};

      if (!canDo) {
        switch (behavior) {
          case "disabled": {
            attributes.disabled = true;
            break;
          }
          case "hidden": {
            attributes.mode = "hidden";
            break;
          }
          case "ignored": {
            attributes.mode = "ignored";
          }
        }
      }
      methodDebug(`Returning attributes`, attributes);
      return attributes;
    },
    loadField(input, force = false, options = {}) {
      const component = this;
      const fieldName = component.definition.name;
      const debugKey = options.debugKey || `loadField_${fieldName}`;
      const methodDebug = debug.extend(debugKey); //eslint-disable-line
      const definition = input || component.definition;
      const permissionAttributes = component.checkPermission();
      methodDebug(`permissionAttributes`, permissionAttributes);
      const field = defaultsDeep(
        permissionAttributes,
        {
          mode: input || definition.mode,
          display: {},
        },
        definition,
        {
          value: definition.value,
        }
      );

      methodDebug(`field after permission check`, JSON.stringify(field));
      try {
        if (field.rules?.length) {
          /* 
            NOTE: This may be buggy. It assumes a certain structure for
            each rule. Have not validated that this is true for all rules
           */
          for (let i = 0; i < field.rules.length; i++) {
            const rule = field.rules[i];
            // {"is_after_date":{"min":"{_data->start_date}"}  }
            const ruleKey = Object.keys(rule)[0];
            const ruleDetails = rule[ruleKey];
            if (!isPlainObject(ruleDetails)) {
              continue;
            }
            // {"min":"{_data->start_date}"}
            const ruleDetailsKey = Object.keys(ruleDetails)[0];
            let ruleValue = ruleDetails[ruleDetailsKey];
            if (typeof ruleValue != "string") {
              continue;
            }
            // {"min":"{_data->start_date}"}
            methodDebug(`ruleValue; [${ruleValue}]`);
            ruleValue = component.$store.getters[
              `${component.context}/dynamicText`
            ]({ url: ruleValue });
            methodDebug(`ruleValue; [${ruleValue}]`);
            ruleDetails[ruleDetailsKey] = ruleValue;
          }
        }
      } catch (e) {
        console.error(
          `Error occurred when checking rules. Assumption made is not valid for these rules`,
          field.rules,
          e
        );
      }
      methodDebug(`in load field`, clone(field));
      component.field = field;
      if (definition.mode == "ignored") {
        methodDebug(`field's mode is ignored. Aborting`);
        return;
      }
      component.deriveComponent();
      if (!component.field.is_container) {
        if (!force) {
          if (component.field.value) {
            component.$store.commit(
              `${component.context}/${STORE_CONSTS.FIELD}`,
              {
                path: component.path,
                value: component.field.value,
              }
            );
            methodDebug(
              `field [${component.field.name}] already has value. Not invoking set field value`
            );
          } else if (component.field.is_static) {
            methodDebug(`is static. Not invoking set field value`);
          } else {
            component.setFieldValue();
          }
        } else {
          methodDebug(`invoking set field value`);
          component.setFieldValue();
        }
      }

      if (this.isDesignMode) {
        methodDebug(`is design mode. Deriving actions`);
        component.deriveActions();
      } else {
        if (component.field.is_static || component.field.is_container) {
          methodDebug(
            `static or container field [${component.field.name}]. Not setting up watch or fetching data`
          );
        } else {
          component.setupWatch();
          component.fetchData();
        }
        component.getSeedData();
      }

      this.updateDynamicComponent++;
    },
    updateFieldDefinition(updatedDefinition) {
      const methodDebug = debug.extend("updateFieldDefinition"); //eslint-disable-line
      const component = this;
      component.loadField(clone(updatedDefinition));
      component.$emit("update_field", clone(updatedDefinition));
    },
    updateFieldPermissions(updatedDefinition) {
      const methodDebug = debug.extend("updateFieldPermissions"); //eslint-disable-line
      const component = this;
      component.loadField(clone(updatedDefinition));
      if (this.isDesignMode) {
        component.$emit("update_field", clone(updatedDefinition));
        return;
      }
      methodDebug(
        `Emitting update_field_permissions from field`,
        this.field.name,
        updatedDefinition
      );
      component.$emit("update_field_permissions", clone(updatedDefinition));
    },
    deriveComponent() {
      const methodDebug = debug.extend("deriveComponent"); //eslint-disable-line
      const component = this;
      component.$store
        .dispatch(`${component.context}/deriveComponent`, component.definition)
        .then((runtimeComponent) => {
          component.currentComponent = runtimeComponent;
        });
    },
    updateData() {
      const methodDebug = debug.extend("updateData"); //eslint-disable-line
      const component = this;
      methodDebug(`in updateData of ${component.field.name}`);
      if (isAnAction(component.field)) {
        //TODO make this generic
        return;
      }
      const value = component.$store.getters[`${component.context}/fieldValue`](
        component.path
      );
      methodDebug(
        `value: [`,
        value,
        "] fieldValue: [",
        component.field.value,
        "]"
      );
      if (!isEqual(component.field.value, value)) {
        methodDebug("Mutating field", component.path, component.field.value);
        const mutation = `${component.context}/${STORE_CONSTS.FIELD}`;
        component.$store.commit(mutation, {
          path: component.path,
          value: component.field.value,
        });
      }
      component.$emit("update", component.field.value);
    },
    setFieldValue() {
      const methodDebug = debug.extend("setFieldValue"); //eslint-disable-line
      methodDebug(`in setFieldValue`);
      const component = this;
      if (isAnAction(component.field)) {
        methodDebug(`Is an action. Ignoring setFieldValue`);
        return;
      }
      if (!component.path) {
        methodDebug(`No path available. Ignoring setFieldValue`);
        return;
      }
      if (!component.$store.hasModule(component.context)) {
        methodDebug(`No store available. Ignoring setFieldValue`);
        return;
      }
      if (component.field.is_container) {
        methodDebug(`Container. Ignoring setFieldValue`);
        return;
      }
      if (component.field.is_static) {
        methodDebug(`Static Field. Ignoring setFieldValue`);
        return;
      }
      const value = component.$store.getters[`${component.context}/fieldValue`](
        component.path
      );
      methodDebug(
        `field value in store`,
        value,
        `isEqual`,
        isEqual(component.field.value, value)
      );
      if (
        value === null ||
        typeof value == "undefined" ||
        isEqual(component.field.value, value)
      ) {
        return;
      }
      methodDebug(
        `in setFieldValue: Updating ${component.name} with value: ${value}`
      );
      component.$set(component.field, "value", value);
    },
    setupWatch() {
      const component = this;
      const debugKey = `setupWatch_${component.definition.name}`;
      const methodDebug = debug.extend(debugKey); //eslint-disable-line
      if (this.watchAlreadySet) {
        return;
      }

      const unsubscribe = component.$store.subscribe((mutation) => {
        const isDataUpdate =
          mutation.type == `${component.context}/${STORE_CONSTS.DATA}`;
        const isFieldUpdate =
          mutation.type == `${component.context}/${STORE_CONSTS.FIELD}`;
        if (isDataUpdate || isFieldUpdate) {
          methodDebug(
            `In watch for [${mutation.type}] in field: ${component.definition.name}`,
            clone(mutation)
          );
          component.setFieldValue();
          if (!component.definition.is_dynamic) {
            return;
          }
          methodDebug(`Field ${component.definition.name} will rerender`);
          component.$nextTick(() => {
            component.reRenderIfNecessary(mutation, { debugKey });
          });
        }
      });
      component._subscribe(unsubscribe);
      this.watchAlreadySet = true;
    },
    reRenderIfNecessary(mutation, options = {}) {
      const component = this;
      const debugKey =
        `reRenderIfNecessary_${options.debugKey}` ||
        `reRenderIfNecessary_${this.definition.name}`;
      const methodDebug = debug.extend(debugKey); //eslint-disable-line
      const mutationPath = mutation?.payload?.path || "";
      const valueHasConditions = Array.isArray(
        component.definition?.conditions
      );
      if (!mutationPath && !valueHasConditions) {
        methodDebug(
          `Ignoring since no mutation path was specified and the value is not conditional`
        );
        return;
      }
      const fieldPath = this.path;
      methodDebug(
        `reRenderIfNecessary: ${this.definition.name}`,
        `mutation`,
        mutation,
        `fieldPath: ${fieldPath}`,
        `mutationPath: ${mutationPath}`
      );
      if (fieldPath && mutationPath && mutationPath.indexOf(fieldPath) != -1) {
        methodDebug(`Ignoring update to self ${this.definition.name}`);
        return;
      }
      const replaceVariables = (url) => {
        const component = this;
        let payload = {
          url: `$\{${url}}`,
          customFunctions: component.replaceIndex(),
          debugKey: options.debugKey,
        };
        let result =
          component.$store.getters[`${component.context}/dynamicText`](payload);
        methodDebug(`After replaceVariables: ${url}`);
        return result;
      };

      let sources = getDataVariablesInFieldDefinition(this.field)
        .map(getKeyFromDataVariable)
        .map(replaceVariables)
        .map((item) => item.replace(/[${}]/g, ""));

      methodDebug(`sources`, sources);
      const dependsOn = this.field.depends_on;
      const isDataUpdate =
        mutation.type == `${component.context}/${STORE_CONSTS.DATA}`;

      if (mutationPath) {
        if (sources.length) {
          methodDebug(`in sources flow`);
          const matchedSources = sources.filter((source) => {
            return areSimilarVariables(source, mutationPath);
          });
          methodDebug(`matchedSources`, matchedSources);
          if (matchedSources.length) {
            methodDebug(
              `Found [${mutationPath}] in list of variables. Rerendering ${this.definition.name}`
            );
            return this.loadField(null, true, { debugKey: options.debugKey });
          }
        } else if (dependsOn) {
          methodDebug(`in dependsOn flow`);
          if (new RegExp(dependsOn).test(mutationPath)) {
            methodDebug(
              `Found [${mutationPath}] in dependsOn. Rerendering ${this.definition.name}`
            );
            return this.loadField(null, true, { debugKey: options.debugKey });
          }
        }
        methodDebug(`neither sources nor dependsOn flow`);
      } else if (isDataUpdate && sources.length) {
        /* Handles the scenario where 
        1. the data is updated
        2. the definition has a value condition which may not depend on other fields but 
        on random data fields
         */
        methodDebug(`is a Data update and sources have been updated`);
        return this.loadField(null, true);
      }

      methodDebug(
        `ignored mutation to path: ${mutationPath} in field: ${this.definition.name}`,
        sources,
        this.definition
      );
    },
    /* IMPORTANT - overrides mixin method - BEGIN */
    async fetchData() {
      const methodDebug = debug.extend("fetchData"); //eslint-disable-line
      const component = this;
      const url = component?.definition?.apis?.data?.url;
      if (!url) {
        return;
      }
      try {
        methodDebug(`before fetchData`, safeClone(this.field.value));
        const data = await component.$store.dispatch(
          `${component.context}/getData`,
          {
            field: {
              definition: clone(component.definition),
              customFunctions: this.replaceIndex(),
            },
          }
        );
        component.$store.commit(`${component.context}/${STORE_CONSTS.FIELD}`, {
          path: component.path,
          value: data,
        });
        if (!component.$store.state?.[`${component.context}`]) {
          debugger;
        } else {
          component.$store.commit(
            `${component.context}/${STORE_CONSTS.ORIGINAL_DATA}`,
            component.$store.state[`${component.context}`].data
          );
        }
        methodDebug(`after fetchData`, safeClone(this.field.value));

        methodDebug(`field data fetched`);
        component.setFieldValue();
      } catch (e) {
        console.error(`in catch block`, e, component.definition);
        /*  TODO Two Issues: 
        1. The server may return a 404 which may need to be ignored
        2. In lists, the field's label is not available
      */

        /* if (component.definition.type != "page_editor") {
          component.displayErrorMessage(
            `Could not fetch data for the field: [${component.definition.label}]`
          );
        } */
      } finally {
        component.setTimers();
        component.setupContextWatch(component?.definition?.apis?.data);
      }
    },
    /* IMPORTANT - overrides mixin method. - END */
    async getSeedData() {
      const methodDebug = debug.extend("getSeedData"); //eslint-disable-line
      const component = this;
      const seedData = await component.$store.dispatch(
        `${component.context}/getSeedData`,
        {
          definition: component.definition,
          customFunctions: this.replaceIndex(),
        }
      );
      methodDebug(`seedData for ${component.name}`, seedData);
      if (seedData) {
        this.$set(this.field, "seedData", seedData);
      }
    },

    isDialog() {
      const methodDebug = debug.extend("isDialog"); //eslint-disable-line
      let result = false;
      const pageDialog = document.querySelector("[aut-page-dialog]");
      if (pageDialog && pageDialog.contains(this.$el)) {
        result = true;
      }
      return result;
    },
    replaceIndex() {
      const methodDebug = debug.extend(`replaceIndex_${this?.field?.name}`);
      const index = this.index;
      methodDebug(`Method invoked with index`, index);
      return function closuredReplaceIndex(url) {
        if (!url) {
          return url;
        }
        let result = url;
        if (typeof index != "undefined" && Number.isInteger(index)) {
          methodDebug(`Replacing in ${url} with index`, index);
          result = url.replaceAll("[i]", `[${index}]`);
        }
        methodDebug(`returning ${result}`);
        return `${result}`;
      };
    },
    async triggerAction(options = {}) {
      const methodDebug = debug.extend("triggerAction"); //eslint-disable-line
      const { definition, eventID } = options || {};
      methodDebug(`triggerAction invoked with`, options);
      const component = this;
      try {
        await component.$store.dispatch(`${component.context}/triggerAction`, {
          actionDefinition: definition,
          customFunctions: this.replaceIndex(),
          isDialog: component.isDialog(),
        });
      } catch (e) {
        console.error(e);
        if (typeof e == "string") {
          component.displayErrorMessage(e);
        } else {
          component.displayErrorMessage(`Operation unsuccessful`, e);
        }
      } finally {
        if (eventID && component.$store.hasModule(component.context)) {
          methodDebug(`event ID passed. Triggering event: [${eventID}]`);
          try {
            component.$store.dispatch(`${component.context}/triggerAction`, {
              actionDefinition: {
                value: {
                  type: "event",
                  name: eventID,
                },
              },
            });
          } catch (e) {
            console.error(`execptino occurred`, e);
          }
        }
      }
    },
  },
};
</script>
